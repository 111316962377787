import { endpoints, labels, externalApplicationUrls } from './constants';
export const environment = {
  production: true,
  type: 'dev',
  b2cSettings: {
    authUrl: "https://b2cdevthefa.b2clogin.com",
    tenant: "b2cdevthefa.onmicrosoft.com",
    clientID: "cef6835f-1d1e-45ea-8578-f595c70a0b34",
    knownAuthorities: "b2cdevthefa.b2clogin.com",
    signInPolicy: "B2C_1A_SIGNUP_SIGNINACTSOC",
    passwordResetPolicy: "B2C_1A_PWRESETCUSTOMOTPPFF_STR",
    updatePasswordPolicy: "B2C_1A_SELFPWRESETPFF_STR",
    updatePasswordPolicyForFancode: "B2C_1A_SELFPWRESETPFF_STR_FAN",
    updateSignInEmailPolicy: "B2C_1A_UpdateSignInEmailPFF_STR_EMAILUPD",
    userAddressPolicy: "B2C_1A_PROFILEADDRESSEDITINMASTERPFF_STRJS",
    updateMobilePolicy:
      "B2C_1A_PROFILEMOBILETTELEPHONEEDITINMASTERPFF_STR_MBLUPD",
    signUpPolicyFA: "B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVERDYN",
    forgottenEmailPolicy:
      "B2C_1A_SIGNUP_SIGNINPROFILEEDITFANCODEV3JSDYN",
    dontKnowMyFanPolicy: "B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER",
    authenticateUserForSettingsPolicy: "B2C_1A_SETTINGSPFF_STR",
    redirectUri: "https://zdevpffwebapp11.z6.web.core.windows.net/",
    //redirectUri: "http://localhost:4020",
    b2cScopes: [
      "https://B2CDevTheFa.onmicrosoft.com/ACTScopeApp/user_impersonation",
      "https://B2CDevTheFa.onmicrosoft.com/ACTScopeApp/READ",
      "https://B2CDevTheFa.onmicrosoft.com/ACTScopeApp/WRITE",
    ],
    b2cParameter: "AM",
    extraQueryParameters: {
      "FAAppToken": "AM"
    },
    env: "zdevpffapimngt1",
  },
  subscriptionKey: "Ocp-Apim-Subscription-Key",
  subscriptionValue: "9a3f41b78c0a4d4faabc7224005e2f9b",
  consentSubscriptionValue: "995b869aa90d4b349e4b28ed354337f8",
  countyListSubscriptionValue: "8b2ac3302871468089cfa0264e2fbbc9",
  listOfDisabilitiesSubscriptionValue: "9a3f41b78c0a4d4faabc7224005e2f9b",
  paymentSubscriptionValue: "c5ab16a2040c44d8bf2f570119a2766e",
  saveDisabilitiesSubscriptionValue: "9a3f41b78c0a4d4faabc7224005e2f9b",
  otherSubscriptions: [
    {
      serviceUrl: 'paas-api-authz/',
      subscriptionValue: 'c5ab16a2040c44d8bf2f570119a2766e'
    },
    {
      serviceUrl: 'referencedata-subky/',
      subscriptionValue: '9a3f41b78c0a4d4faabc7224005e2f9b'
    },
    {
      serviceUrl: 'referencedata-authz/',
      subscriptionValue: ''
    },
    {
      serviceUrl: 'playerreg-authz/',
      subscriptionValue: ''
    },
    {
      serviceUrl: 'b2cuseraccount-api-authz/',
      subscriptionValue: ''
    },
    {
      serviceUrl: 'dbs-authz/',
      subscriptionValue: ''
    }
  ],
  apiEndPoints: {
    headerApiUrl:
      "https://zdevpffapimngt1.azure-api.net/fasite/api/Navigation/TopLinksAsHtml?Method=TopLinksAsHtml&showSignIn=false&userAuthenticated=false&userName= &urlSignIn= &urlSignOut= &UrlMyAccount= &urlIsUserSignedIn= &hideFooterSponsors=false&hideFooterEmailForm=false&hideMerchandiseLink=false&useMinimalCss=false",
    footerApiUrl:
      "https://zdevpffapimngt1.azure-api.net/fasite/api/Navigation/HeaderAndFooterAsHtml?Method=HeaderAndFooterAsHtml&showSignIn=false&userAuthenticated=false&userName= &urlSignIn= &urlSignOut= &UrlMyAccount= &urlIsUserSignedIn= &hideFooterSponsors=false&hideFooterEmailForm=false&hideMerchandiseLink=false&useMinimalCss=false",
    serverUrl: "https://zdevpffapimngt1.azure-api.net/",
    paymentServerUrl: "https://zdevpayapimngt1.azure-api.net/",
    refregServerUrl: "https://zdevpffwebapp1.z6.web.core.windows.net/",
    playerRegServerUrl: "https://zdevpffwebapp23.z6.web.core.windows.net/",
    wholeGameSystem: "https://wholegame.thefa.test/",
    refreeRegistration: "https://zdevpffwebapp1.z6.web.core.windows.net/#/home",
    leadPlayMakerLink: "http://cloud-stg.thefa.com/learning",
    dispensationRequest: "https://dispensationdev.thefa.com/Dispensation-Request---New/",
    applicationDashboard: "https://dispensationdev.thefa.com/parent-carer-dashboard/",
    dbsService: "https://zdevpffapimngt1.azure-api.net/",
    services: {
      ipsAuthzService: "ips-api-authz/",
      ipsService: "accountmanagement/",
      mediaService: "media/thefadev.com/",
      notificationService: "notification/dev.com/",
      CRSservice: "FACoreServiceAPI.dev.com/",
      refereeService: "referee.dev.com/",
      playerReg: "playerreg-authz/",
      referenceDataSubky: "referencedata-subky/",
      referenceDataAuthz: "referencedata-authz/",
      passApi: "paas-api-authz/",
      b2cuseraccount: "b2cuseraccount-api-authz/",
      dbsService:"dbs-authz/"
    },
    endpoints: endpoints,
    labels: labels,
    externalApplicationUrls: externalApplicationUrls
  },

  edi: {
    apiEndPoints: {
      services: {
        ediAuthz: "edni-api-authz/",
        ediSubky: "edni-api-subky/"
      },
      getEDIDetailsApi:
        "https://zdevpffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/GetEDnIDetails",
      addEDIDetailsApi:
        "https://zdevpffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/AddEDnIDetails",
      deleteEDIDetailsApi:
        "https://zdevpffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/DeleteEDnIDetails",
      getEDIQuestionnaireApi:
        // "https://zdevpffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/GetEDnIQuestionnaire",
        "https://zdevpffapimngt1.azure-api.net/edni-api-subky/v1/api/EDnI/GetEDnIQuestionnaire"
    },
    labels: {
      diversityAndInclusion: {
        ediHeading: "Diversity and Inclusion",
      },
      ediQuestionnaireMapping: [
        {
          id: 1,
          category: "genderAtBirth",
        },
        {
          id: 2,
          category: "gender",
        },
        {
          id: 3,
          category: "sexuality",
        },
        {
          id: 4,
          category: "schoolType",
        },
        {
          id: 5,
          category: "parentOccupation",
        },
        {
          id: 6,
          category: "disability",
        },
        {
          id: 7,
          category: "disabilityProblems",
        },
        {
          id: 8,
          category: "pathwayEligibility",
        },
        {
          id: 9,
          category: "ethnicity",
        },
        {
          id: 10,
          category: "religion",
        },
      ],
    },
    externalApplicationUrls: {
      referee: "https://zdevpffwebapp1.z6.web.core.windows.net/#/success",
      player: "https://zdevpffwebapp23.z6.web.core.windows.net/#/redirection?app=edi",
      Discipline: "",
      education: "",
      consent: "https://zdevpffwebapp23.z6.web.core.windows.net/#/redirection?app=edi",
      wgs: "https://wholegame.thefa.test",
    }
  },
};